import { render, staticRenderFns } from "./inviting.vue?vue&type=template&id=4bf87f69&scoped=true"
import script from "./inviting.vue?vue&type=script&lang=js"
export * from "./inviting.vue?vue&type=script&lang=js"
import style0 from "./inviting.vue?vue&type=style&index=0&id=4bf87f69&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bf87f69",
  null
  
)

export default component.exports